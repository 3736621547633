// extracted by mini-css-extract-plugin
export var carouselContainer = "z_vj d_w d_H d_bf d_Z";
export var carouselContainerCards = "z_vk z_vj d_w d_H d_bf d_Z";
export var carouselContainerSides = "z_vl d_w d_H d_Z";
export var prevCarouselItem = "z_vm d_w d_H d_0 d_k";
export var prevCarouselItemL = "z_vn z_vm d_w d_H d_0 d_k";
export var moveInFromLeft = "z_vp";
export var prevCarouselItemR = "z_vq z_vm d_w d_H d_0 d_k";
export var moveInFromRight = "z_vr";
export var selectedCarouselItem = "z_vs d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "z_vt z_vs d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "z_vv z_vs d_w d_H d_Z d_bf";
export var nextCarouselItem = "z_vw d_w d_H d_0 d_k";
export var nextCarouselItemL = "z_vx z_vw d_w d_H d_0 d_k";
export var nextCarouselItemR = "z_vy z_vw d_w d_H d_0 d_k";
export var arrowContainer = "z_vz d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "z_vB z_vz d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "z_vC z_vB z_vz d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "z_vD z_vz d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "z_vF";
export var nextArrowContainerHidden = "z_vG z_vD z_vz d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "z_kG d_0";
export var prevArrow = "z_vH z_kG d_0";
export var nextArrow = "z_vJ z_kG d_0";
export var carouselIndicatorContainer = "z_vK d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "z_vL d_w d_bz d_bF";
export var carouselText = "z_vM d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "z_vN z_vM d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "z_vP d_b7";
export var carouselIndicator = "z_vQ z_vP d_b7";
export var carouselIndicatorSelected = "z_vR z_vP d_b7";
export var arrowsContainerTopRight = "z_vS d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "z_vT d_0 d_bl d_bC";
export var arrowsContainerSides = "z_vV d_0 d_bl d_bC";
export var smallArrowsBase = "z_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "z_vX z_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "z_vY z_vX z_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "z_vZ z_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "z_v0 z_vZ z_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "z_p9";
export var iconSmall = "z_v1";
export var multipleWrapper = "z_v2 d_bC d_bF d_bf";
export var multipleImage = "z_v3 d_bC";
export var sidesPrevContainer = "z_v4 z_vX z_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "z_v5 z_vX z_vW d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";